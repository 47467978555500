.a-list {
  height: 4.8rem;
}

.a-list .a-item {
  background: #fff;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  align-items: center;
  -webkit-align-items: center;
  border-bottom: 0.013333rem solid rgba(240, 240, 240, 0.9);
  height: 1.6rem;
  box-sizing: border-box;
  padding: 0 0.426667rem;
}

.a-list .a-item .a-i-left {
  flex: 1 1;
  -webkit-flex: 1;
  box-sizing: border-box;
  padding-left: 1.066667rem;
}

.a-list .a-item .a-i-left span {
  font-size: 0.453333rem;
  color: #212121;
  line-height: 0.64rem;
}

.a-list .a-item .a-i-left.a-i-name {
  background: url("../../assets/imgs/ysd/common/i-a-i1.png") 0 center no-repeat;
  background-size: 0.64rem 0.64rem;
}

.a-list .a-item .a-i-left.a-i-live {
  background: url("../../assets/imgs/ysd/common/i-a-i2.png") 0 center no-repeat;
  background-size: 0.64rem 0.64rem;
}

.a-list .a-item .a-i-left.a-i-info {
  background: url("../../assets/imgs/ysd/common/i-a-i3.png") 0 center no-repeat;
  background-size: 0.64rem 0.64rem;
}

.a-list .a-item .a-i-right {
  flex: 1 1;
  -webkit-flex: 1;
  text-align: right;
  box-sizing: border-box;
  padding-right: 0.533333rem;
  background: url("../../assets/imgs/ysd/common/i-a-arrow.png") right center no-repeat;
  background-size: 0.32rem 0.64rem;
}

.a-list .a-item .a-i-right .a-i-right-text {
  font-size: 0.453333rem;
  line-height: 0.64rem;
  color: #9e9e9e;
}

.a-list .a-item .a-i-right .blue {
  color: #108EE9;
}

.a-list .a-item .a-i-right .red {
  color: #FF4248;
}

.a-list .a-item .a-i-right .grey {
  color: #9e9e9e;
}

.a-list .a-item:last-child {
  border: none;
}