.index {
  margin-top: 1.28rem;
  min-height: 21.706667rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  overflow-x: hidden;
  -webkit-overflow-scroll: touch;
}

.index .m-sys-header {
  width: 100%;
  height: 1.146667rem;
  position: fixed;
  left: 0;
  top: 1.146667rem;
  background: #FFFFFF;
  box-shadow: 0 -0.013333rem 0 0 #D5D5D5;
}

.index .m-sys-header .m-sys-tab-wrap {
  display: -webkit-flex;
  display: flex;
}

.index .m-sys-header .m-sys-tab {
  display: inline-block;
  -webkit-flex: 1 1;
  flex: 1 1;
  text-align: center;
}

.index .m-sys-header .m-sys-tab .m-sys-tab-text {
  display: inline-block;
  font-size: 0.373333rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #1E1E1E;
  line-height: 1.146667rem;
  text-align: center;
}

.index .m-sys-header .m-sys-tab .active {
  border-bottom: 0.026667rem solid #4C7BFE;
}

.index .m-sys-content {
  margin-top: 1.28rem;
}

.index .m-sys-content .m-sys-view {
  display: none;
}

.index .m-sys-content .active {
  display: block;
}

.index .m-sys-content .mbox {
  width: 100%;
  height: 100%;
  min-height: 48.72rem;
}

.index .m-sys-content .mbox .bill-null .pic {
  width: 10rem;
  height: 10.053333rem;
  background: url(./img/billNull.png) no-repeat;
  background-size: 10rem 10.053333rem;
}

.index .m-sys-content .mbox .bill-null .btnNs {
  margin: 2.133333rem auto 3.573333rem;
  width: 5.333333rem;
  height: 1.306667rem;
  border-radius: 0.653333rem;
  border: 0.026667rem solid #4C7BFE;
}

.index .m-sys-content .mbox .bill-null .btnNs .textBN {
  display: inline-block;
  width: 100%;
  font-size: 0.4rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #4C7BFE;
  line-height: 1.306667rem;
  text-align: center;
}

.index .m-sys-content .mbox .module {
  margin: 0.32rem 0.4rem;
  width: 9.2rem;
  height: 4.413333rem;
  background: #FFFFFF;
  border-radius: 0.106667rem;
}

.index .m-sys-content .mbox .module .card {
  margin: 0 0.453333rem;
}

.index .m-sys-content .mbox .module .card .headTop {
  display: -webkit-flex;
  display: flex;
  height: 1.106667rem;
  border-bottom: 0.013333rem solid #ccc;
}

.index .m-sys-content .mbox .module .card .headTop .text,
.index .m-sys-content .mbox .module .card .headTop .date {
  -webkit-flex: 1 1;
  flex: 1 1;
}

.index .m-sys-content .mbox .module .card .headTop .text {
  font-size: 0.373333rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #1E1E1E;
  line-height: 1.106667rem;
}

.index .m-sys-content .mbox .module .card .headTop .date {
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #4A4A4A;
  line-height: 1.106667rem;
  text-align: right;
}

.index .m-sys-content .mbox .module .card .contain {
  margin-top: 0.36rem;
}

.index .m-sys-content .mbox .module .card .contain .left {
  display: inline-block;
  width: 2.04rem;
  height: 2.28rem;
}

.index .m-sys-content .mbox .module .card .contain .left img {
  width: 2.04rem;
  height: 2.28rem;
}

.index .m-sys-content .mbox .module .card .contain .right {
  display: inline-block;
  padding-left: 0.493333rem;
}

.index .m-sys-content .mbox .module .card .contain .right div:nth-child(1) {
  width: 5.653333rem;
  font-size: 0.373333rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #4A4A4A;
  line-height: 0.533333rem;
}

.index .m-sys-content .mbox .module .card .contain .right div:nth-child(2) {
  padding: 0.266667rem 0 0.133333rem;
}

.index .m-sys-content .mbox .module .card .contain .right div:nth-child(2) span {
  font-size: 0.373333rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #E53228;
  line-height: 0.533333rem;
}

.index .m-sys-content .mbox .module .card .contain .right div:nth-child(3) span {
  font-size: 0.373333rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #9B9B9B;
  line-height: 0.533333rem;
}

.index .m-sys-content .mbox .BtnBill {
  margin: 0 0.453333rem;
  width: 9.2rem;
  height: 5.933333rem;
  background: #FFFFFF;
  border-radius: 0.106667rem;
}

.index .m-sys-content .mbox .BtnBill .card {
  margin: 0.32rem 0.453333rem;
}

.index .m-sys-content .mbox .BtnBill .card .headTop {
  display: -webkit-flex;
  display: flex;
  height: 1.106667rem;
  border-bottom: 0.013333rem solid #ccc;
}

.index .m-sys-content .mbox .BtnBill .card .headTop .text,
.index .m-sys-content .mbox .BtnBill .card .headTop .date {
  -webkit-flex: 1 1;
  flex: 1 1;
}

.index .m-sys-content .mbox .BtnBill .card .headTop .text {
  font-size: 0.373333rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #1E1E1E;
  line-height: 1.106667rem;
}

.index .m-sys-content .mbox .BtnBill .card .headTop .date {
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #4A4A4A;
  line-height: 1.106667rem;
  text-align: right;
}

.index .m-sys-content .mbox .BtnBill .card .contain {
  margin-top: 0.36rem;
}

.index .m-sys-content .mbox .BtnBill .card .contain .left {
  display: inline-block;
  width: 2.04rem;
  height: 2.28rem;
}

.index .m-sys-content .mbox .BtnBill .card .contain .left img {
  width: 2.04rem;
  height: 2.28rem;
}

.index .m-sys-content .mbox .BtnBill .card .contain .right {
  display: inline-block;
  padding-left: 0.493333rem;
}

.index .m-sys-content .mbox .BtnBill .card .contain .right div:nth-child(1) {
  width: 5.653333rem;
  font-size: 0.373333rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #4A4A4A;
  line-height: 0.533333rem;
}

.index .m-sys-content .mbox .BtnBill .card .contain .right div:nth-child(2) {
  padding: 0.266667rem 0 0.133333rem;
}

.index .m-sys-content .mbox .BtnBill .card .contain .right div:nth-child(2) span {
  font-size: 0.373333rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #E53228;
  line-height: 0.533333rem;
}

.index .m-sys-content .mbox .BtnBill .card .contain .right div:nth-child(3) span {
  font-size: 0.373333rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #9B9B9B;
  line-height: 0.533333rem;
}

.index .m-sys-content .mbox .BtnBill .card .footers {
  margin: 0.533333rem 0.24rem 0.533333rem 5.8rem;
  width: 2.4rem;
  height: 0.853333rem;
  background: #4C7BFE;
  border-radius: 0.493333rem;
}

.index .m-sys-content .mbox .BtnBill .card .footers .btnText {
  display: block;
  font-size: 0.373333rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 0.853333rem;
  text-align: center;
}