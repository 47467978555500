* {
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

html,
body {
  /* 半透明改为全透明遮罩层 */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  height: 100%;
}

.main {
  -webkit-flex: 1 1;
  flex: 1 1;
  display: -webkit-flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  background: #f4f5fa;
}

.page {
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  -webkit-flex: 1 1;
  flex: 1 1;
  min-height: calc(100vh - 2.666667rem);
}

.nav-bar {
  /* width: 120px; */
  position: fixed;
  width: 100%;
  top: 0;
  border-bottom: 0.013333rem solid #ddd;
  z-index: 9999;
}

.am-tab-bar-tab-icon {
  width: 0.64rem;
  height: 0.64rem;
  color: #ccc;
}

.am-navbar-light {
  color: #ccc;
}

.am-tab-bar-tab-title {
  font-size: 0.266667rem;
  color: #e7e7e7;
}